* {
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Light.19f9d88c.woff") format("woff"), url("Montserrat-Light.0cc76396.ttf") format("truetype");
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: Courier New, Courier, monospace;
  font-size: 20px;
  line-height: 1.5;
  position: relative;
}

main {
  flex-direction: column;
  min-width: 350px;
  padding-bottom: 148px;
  display: flex;
}

section {
  margin-bottom: 32px;
  display: flex;
}

p {
  max-width: 700px;
  margin: 0 0 20px;
}

.project p {
  max-width: 400px;
  margin: 0 0 32px;
}

.left {
  justify-content: left;
  align-items: center;
  margin-left: 25%;
}

.centered {
  justify-content: center;
  align-items: center;
  width: 100%;
}

ul {
  min-width: 200px;
  margin-top: 0;
  margin-right: 24px;
  padding: 0;
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  color: #333;
}

.section-title {
  text-align: right;
  text-transform: uppercase;
  width: 25%;
  margin: 0;
  padding-right: 24px;
  font-size: 20px;
  font-weight: 400;
}

.section-body {
  text-align: left;
  width: 75%;
  padding-right: 12%;
  display: flex;
}

.avatar {
  border-radius: 50%;
  width: 96px;
  height: 96px;
  margin: 50px 0 24px 25%;
}

.title {
  color: #ffd952;
  text-transform: uppercase;
  margin: 0;
  font-family: Avenir Next Condensed, Arial, Helvetica, sans-serif;
  font-size: 90px;
  position: absolute;
  top: 480px;
  left: -280px;
  transform: rotate(90deg);
}

.project-title {
  text-transform: uppercase;
  text-decoration: underline;
}

.project, .contact p {
  margin-bottom: .75em;
}

.project span:hover {
  background-color: #ffe992;
}

.link {
  text-decoration: underline;
}

.link:hover {
  background-color: #ffe992;
}

input, textarea {
  text-transform: uppercase;
  border: .5px solid #333;
  border-radius: 4px;
  width: 375px;
  margin-bottom: 6px;
  padding: 6px;
  font-size: 16px;
}

input[type="submit"] {
  color: #ffd952;
  background-color: #000;
  width: 300px;
  padding: 6px;
}

input[type="submit"]:hover {
  cursor: pointer;
}

textarea {
  resize: none;
}

input:focus, textarea:focus {
  outline: none;
}

#myEmail:hover, .resume span:hover {
  cursor: pointer;
  background-color: #ffe992;
}

#clippy:hover, .resume a :hover {
  cursor: pointer;
}

#clippy {
  vertical-align: -2px;
  width: 16px;
  height: 16px;
}

.pdf {
  vertical-align: -3px;
  height: 20px;
  margin-left: 2px;
}

.resume span, .copy {
  text-decoration: underline;
}

.copy #copied {
  text-decoration: none;
}

footer {
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
  padding-top: 8px;
  display: flex;
  position: absolute;
  bottom: 0;
}

footer p {
  color: #fff;
  margin: 0;
  font-size: 16px;
}

.social-icon {
  width: 18px;
  height: 18px;
}

@media only screen and (width <= 1200px) {
  .section-body {
    flex-direction: column;
  }
}

@media only screen and (width <= 750px) {
  .section-title {
    font-size: 14px;
  }

  .section-body {
    text-align: justify;
    padding-right: 6%;
  }

  .title {
    text-align: center;
    background: #000;
    width: 100%;
    padding: 12px;
    font-size: 40px;
    font-weight: 500;
    top: 0;
    left: 0;
    transform: none;
  }

  .avatar {
    margin: 96px 0 24px 25%;
  }

  body {
    font-size: 14px;
    line-height: 1.25;
  }
}

/*# sourceMappingURL=east-bay-gems.5fdb0795.css.map */

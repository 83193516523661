* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Montserrat";
  src: url("/src/assets/fonts/Montserrat-Light.woff") format("woff"),
    url("/src/assets/fonts/Montserrat-Light.ttf") format("truetype");
}

body {
  position: relative;
  margin: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 20px;
  line-height: 1.5;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  padding-bottom: 148px;
  min-width: 350px;
}

section {
  display: flex;
  margin-bottom: 32px;
}

p {
  margin: 0 0 20px 0;
  max-width: 700px;
}

.project p {
  max-width: 400px;
  margin: 0 0 32px 0;
}

.left {
  margin-left: 25%;
  justify-content: left;
  align-items: center;
}

.centered {
  width: 100%;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
  padding: 0;
  margin-right: 24px;
  margin-top: 0;
  min-width: 200px;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  color: #333;
}

.section-title {
  width: 25%;
  text-align: right;
  padding-right: 24px;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
}

.section-body {
  width: 75%;
  display: flex;
  text-align: left;
  padding-right: 12%;
}

.avatar {
  margin: 50px 0 24px 25%;
  border-radius: 50%;
  height: 96px;
  width: 96px;
}

.title {
  transform: rotate(90deg);
  position: absolute;
  left: -280px;
  top: 480px;
  font-family: "Avenir Next Condensed", Arial, Helvetica, sans-serif;
  font-size: 90px;
  color: #ffd952;
  text-transform: uppercase;
  margin: 0;
}

.project-title {
  text-decoration: underline;
  text-transform: uppercase;
}

.project,
.contact p {
  margin-bottom: 0.75em;
}

.project span:hover {
  background-color: #ffe992;
}

.link {
  text-decoration: underline;
}

.link:hover {
  background-color: #ffe992;
}

input,
textarea {
  margin-bottom: 6px;
  padding: 6px;
  width: 375px;
  border: solid #333 0.5px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 16px;
}

input[type="submit"] {
  color: #ffd952;
  background-color: black;
  padding: 6px;
  width: 300;
}

input[type="submit"]:hover {
  cursor: pointer;
}

textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
}

#myEmail:hover,
.resume span:hover {
  cursor: pointer;
  background-color: #ffe992;
}
#clippy:hover,
.resume a :hover {
  cursor: pointer;
}

#clippy {
  vertical-align: -2px;
  height: 16px;
  width: 16px;
}

.pdf {
  vertical-align: -3px;
  height: 20px;
  margin-left: 2px;
}

.resume span {
  text-decoration: underline;
}

.copy {
  text-decoration: underline;
}

.copy #copied {
  text-decoration: none;
}

footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 100%;
  padding-top: 8px;
  background-color: black;
}

footer p {
  color: white;
  margin: 0;
  font-size: 16px;
}

.social-icon {
  height: 18px;
  width: 18px;
}

@media only screen and (max-width: 1200px) {
  .section-body {
    flex-direction: column;
  }
}

@media only screen and (max-width: 750px) {
  .section-title {
    font-size: 14px;
  }
  .section-body {
    padding-right: 6%;
    text-align: justify;
  }
  .title {
    transform: none;
    left: 0px;
    top: 0px;
    width: 100%;
    font-size: 40px;
    text-align: center;
    background: black;
    font-weight: 500;
    padding: 12px;
  }
  .avatar {
    margin: 96px 0 24px 25%;
  }
  body {
    line-height: 1.25;
    font-size: 14px;
  }
}
